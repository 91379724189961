@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F6F4;
  height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #15143A;
}

/* set every element color to #15143A except icons */
* ::not(i) {
  color: #15143A;
}

/* bg color */

.bg-color {
  background-color: #F8F6F4;
}

/* different colors */
.text-primary {
  color: #15143A;
}

.text-secondary {
  color: #F8F6F4;
}

.text-tertiary {
  color: #F8F6F4;
}

.bg-primary {
  background-color: #15143A;
}

.bg-secondary {
  background-color: #F3EEE8;
}

.risk-dark-bg {
  background-color: #E95536;
}

.warn-dark-bg {
  background-color: #FBCA9A;
}

.safe-dark-bg {
  background-color: #3DBAC2;
}

.risk-light-bg {
  background-color: #F6DFD8;
}

.warn-light-bg {
  background-color: #F9EBDC;
}

.safe-light-bg {
  background-color: #D4EDED;
}

.text-dark-red {
  color: #851E08;
}

.text-dark-yellow {
  color: #6A3806;
}

.text-dark-green {
  color: #042C2F;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
